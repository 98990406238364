<template>
  <div
    class="wrap__content"
    v-bind:class="{ 'wrap__content--placeholder': !user || !application }"
  >
    <div class="page__placeholder" v-if="!user || !application">
      <div class="form__block--placeholder">
        <div class="form__placeholder--circle">
          <div class="placeholder__circle">
            <svg
              width="73"
              height="73"
              viewBox="0 0 73 73"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="36.5"
                cy="36"
                r="24"
                stroke="#FFF7E1"
                stroke-width="2"
              />
              <g filter="url(#filter0_d_22_5658)">
                <path
                  d="M16.1604 23C13.5332 26.8866 12 31.5673 12 36.6045C12 50.0778 22.969 61 36.5 61C50.031 61 61 50.0778 61 36.6045C61 34.8685 60.8179 33.1748 60.4716 31.5413"
                  stroke="#FFCC47"
                  stroke-width="4"
                  stroke-linecap="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_22_5658"
                  x="5"
                  y="15.9998"
                  width="63"
                  height="52.0002"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2.5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 0.722826 0 0 0 0 0 0 0 0 0.5 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_22_5658"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_22_5658"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        <div class="form__placeholder--logo">
          <img src="../../../../assets/img/logo.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="content__page--block" v-if="user && application">
      <div class="content__page content__page--margintop">
        <div class="container">
          <div class="content__page--back">
            <a :href="'/' + $i18n.locale + '/account/'">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 12H5"
                  stroke="#0E1839"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 19L5 12L12 5"
                  stroke="#0E1839"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {{ $t('system.back') }}
            </a>
          </div>

          <div class="content__title--block">
            <div class="section__title--mini">
              Заявление на субсидирование затрат туроператоров в сфере въездного
              туризма
            </div>
            <div class="content__title section__title">
              <strong>за каждого иностранного туриста</strong>
            </div>
          </div>

          <div class="add_tourist--section add_tourist--section-transition">
            <div class="add add_tourist--block">
              <div class="add_tourist__section--title">
                Данные иностранного туриста
              </div>
              <div class="table__block--style">
                <v-data-table
                  :headers="headers"
                  :items="userDataSave"
                  :loading="loading"
                  :options.sync="options"
                  hide-default-footer
                >
                </v-data-table>
              </div>
            </div>
          </div>

          <div class="add_tourist--section">
            <div class="add_tourist__section--title">
              Подтверждающие документы
            </div>

            <div class="add_tourist--filelist">
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="file_document_1.class"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Копия документа, удостоверяющего личность иностранного
                      туриста
                    </div>

                    <div
                      class="fileitem__block--file"
                      v-if="file_document_1.name"
                    >
                      <div class="fileitem__block--icon">
                        <svg
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.1242 13.5002L12.3742 15.7502L16.8742 11.2502M8.8133 5.28438C9.62052 5.21996 10.3869 4.90253 11.0032 4.37729C12.4415 3.15158 14.5569 3.15158 15.9952 4.37729C16.6116 4.90253 17.3779 5.21996 18.1852 5.28438C20.0689 5.4347 21.5648 6.93055 21.7151 8.81428C21.7794 9.6215 22.0969 10.3878 22.6222 11.0042C23.8478 12.4425 23.8478 14.5579 22.6222 15.9962C22.0969 16.6125 21.7794 17.3789 21.7151 18.1862C21.5648 20.0699 20.0689 21.5657 18.1852 21.7161C17.378 21.7804 16.6116 22.0979 15.9952 22.6232C14.5569 23.8488 12.4415 23.8488 11.0032 22.6232C10.3869 22.0979 9.62052 21.7804 8.8133 21.7161C6.92957 21.5657 5.43372 20.0699 5.2834 18.1862C5.21898 17.3789 4.90155 16.6125 4.37631 15.9962C3.1506 14.5579 3.1506 12.4425 4.37631 11.0042C4.90155 10.3878 5.21898 9.6215 5.2834 8.81428C5.43372 6.93055 6.92957 5.4347 8.8133 5.28438Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div class="fileitem__block--text">
                        {{ file_document_1.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 14.5632L12.8539 20.8056C12.101 21.5704 11.0797 22 10.0149 22C8.9501 22 7.92889 21.5704 7.17594 20.8056C6.423 20.0409 6 19.0037 6 17.9222C6 16.8406 6.423 15.8034 7.17594 15.0387L13.322 8.79625C13.824 8.28642 14.5048 8 15.2147 8C15.9246 8 16.6054 8.28642 17.1073 8.79625C17.6093 9.30608 17.8913 9.99756 17.8913 10.7186C17.8913 11.4396 17.6093 12.1311 17.1073 12.6409L10.9546 18.8833C10.7036 19.1382 10.3632 19.2814 10.0082 19.2814C9.65329 19.2814 9.31289 19.1382 9.06191 18.8833C8.81093 18.6284 8.66993 18.2827 8.66993 17.9222C8.66993 17.5616 8.81093 17.2159 9.06191 16.961L14.7399 11.2008"
                            stroke="#6B69DF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div class="fileitem__block--text">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--help"
                    v-tooltip.top-center="
                      'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                    "
                    >?</span
                  >
                </div>
              </div>
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="file_document_2.class"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Договор на туристское обслуживание, с иностранным туристом
                    </div>

                    <div
                      class="fileitem__block--file"
                      v-if="file_document_2.name"
                    >
                      <div class="fileitem__block--icon">
                        <svg
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.1242 13.5002L12.3742 15.7502L16.8742 11.2502M8.8133 5.28438C9.62052 5.21996 10.3869 4.90253 11.0032 4.37729C12.4415 3.15158 14.5569 3.15158 15.9952 4.37729C16.6116 4.90253 17.3779 5.21996 18.1852 5.28438C20.0689 5.4347 21.5648 6.93055 21.7151 8.81428C21.7794 9.6215 22.0969 10.3878 22.6222 11.0042C23.8478 12.4425 23.8478 14.5579 22.6222 15.9962C22.0969 16.6125 21.7794 17.3789 21.7151 18.1862C21.5648 20.0699 20.0689 21.5657 18.1852 21.7161C17.378 21.7804 16.6116 22.0979 15.9952 22.6232C14.5569 23.8488 12.4415 23.8488 11.0032 22.6232C10.3869 22.0979 9.62052 21.7804 8.8133 21.7161C6.92957 21.5657 5.43372 20.0699 5.2834 18.1862C5.21898 17.3789 4.90155 16.6125 4.37631 15.9962C3.1506 14.5579 3.1506 12.4425 4.37631 11.0042C4.90155 10.3878 5.21898 9.6215 5.2834 8.81428C5.43372 6.93055 6.92957 5.4347 8.8133 5.28438Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div class="fileitem__block--text">
                        {{ file_document_2.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 14.5632L12.8539 20.8056C12.101 21.5704 11.0797 22 10.0149 22C8.9501 22 7.92889 21.5704 7.17594 20.8056C6.423 20.0409 6 19.0037 6 17.9222C6 16.8406 6.423 15.8034 7.17594 15.0387L13.322 8.79625C13.824 8.28642 14.5048 8 15.2147 8C15.9246 8 16.6054 8.28642 17.1073 8.79625C17.6093 9.30608 17.8913 9.99756 17.8913 10.7186C17.8913 11.4396 17.6093 12.1311 17.1073 12.6409L10.9546 18.8833C10.7036 19.1382 10.3632 19.2814 10.0082 19.2814C9.65329 19.2814 9.31289 19.1382 9.06191 18.8833C8.81093 18.6284 8.66993 18.2827 8.66993 17.9222C8.66993 17.5616 8.81093 17.2159 9.06191 16.961L14.7399 11.2008"
                            stroke="#6B69DF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div class="fileitem__block--text">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--help"
                    v-tooltip.top-center="
                      'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                    "
                    >?</span
                  >
                </div>
              </div>
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="file_document_3.class"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Документ, подтверждающий оплату туристского продукта по
                      Договору
                    </div>

                    <div
                      class="fileitem__block--file"
                      v-if="file_document_3.name"
                    >
                      <div class="fileitem__block--icon">
                        <svg
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.1242 13.5002L12.3742 15.7502L16.8742 11.2502M8.8133 5.28438C9.62052 5.21996 10.3869 4.90253 11.0032 4.37729C12.4415 3.15158 14.5569 3.15158 15.9952 4.37729C16.6116 4.90253 17.3779 5.21996 18.1852 5.28438C20.0689 5.4347 21.5648 6.93055 21.7151 8.81428C21.7794 9.6215 22.0969 10.3878 22.6222 11.0042C23.8478 12.4425 23.8478 14.5579 22.6222 15.9962C22.0969 16.6125 21.7794 17.3789 21.7151 18.1862C21.5648 20.0699 20.0689 21.5657 18.1852 21.7161C17.378 21.7804 16.6116 22.0979 15.9952 22.6232C14.5569 23.8488 12.4415 23.8488 11.0032 22.6232C10.3869 22.0979 9.62052 21.7804 8.8133 21.7161C6.92957 21.5657 5.43372 20.0699 5.2834 18.1862C5.21898 17.3789 4.90155 16.6125 4.37631 15.9962C3.1506 14.5579 3.1506 12.4425 4.37631 11.0042C4.90155 10.3878 5.21898 9.6215 5.2834 8.81428C5.43372 6.93055 6.92957 5.4347 8.8133 5.28438Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div class="fileitem__block--text">
                        {{ file_document_3.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 14.5632L12.8539 20.8056C12.101 21.5704 11.0797 22 10.0149 22C8.9501 22 7.92889 21.5704 7.17594 20.8056C6.423 20.0409 6 19.0037 6 17.9222C6 16.8406 6.423 15.8034 7.17594 15.0387L13.322 8.79625C13.824 8.28642 14.5048 8 15.2147 8C15.9246 8 16.6054 8.28642 17.1073 8.79625C17.6093 9.30608 17.8913 9.99756 17.8913 10.7186C17.8913 11.4396 17.6093 12.1311 17.1073 12.6409L10.9546 18.8833C10.7036 19.1382 10.3632 19.2814 10.0082 19.2814C9.65329 19.2814 9.31289 19.1382 9.06191 18.8833C8.81093 18.6284 8.66993 18.2827 8.66993 17.9222C8.66993 17.5616 8.81093 17.2159 9.06191 16.961L14.7399 11.2008"
                            stroke="#6B69DF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div class="fileitem__block--text">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--help"
                    v-tooltip.top-center="
                      'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                    "
                    >?</span
                  >
                </div>
              </div>

              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="file_document_4.class"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Факт пересечения государственной границы РК туриста
                    </div>

                    <div
                      class="fileitem__block--file"
                      v-if="file_document_4.name"
                    >
                      <div class="fileitem__block--icon">
                        <svg
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.1242 13.5002L12.3742 15.7502L16.8742 11.2502M8.8133 5.28438C9.62052 5.21996 10.3869 4.90253 11.0032 4.37729C12.4415 3.15158 14.5569 3.15158 15.9952 4.37729C16.6116 4.90253 17.3779 5.21996 18.1852 5.28438C20.0689 5.4347 21.5648 6.93055 21.7151 8.81428C21.7794 9.6215 22.0969 10.3878 22.6222 11.0042C23.8478 12.4425 23.8478 14.5579 22.6222 15.9962C22.0969 16.6125 21.7794 17.3789 21.7151 18.1862C21.5648 20.0699 20.0689 21.5657 18.1852 21.7161C17.378 21.7804 16.6116 22.0979 15.9952 22.6232C14.5569 23.8488 12.4415 23.8488 11.0032 22.6232C10.3869 22.0979 9.62052 21.7804 8.8133 21.7161C6.92957 21.5657 5.43372 20.0699 5.2834 18.1862C5.21898 17.3789 4.90155 16.6125 4.37631 15.9962C3.1506 14.5579 3.1506 12.4425 4.37631 11.0042C4.90155 10.3878 5.21898 9.6215 5.2834 8.81428C5.43372 6.93055 6.92957 5.4347 8.8133 5.28438Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div class="fileitem__block--text">
                        {{ file_document_4.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 14.5632L12.8539 20.8056C12.101 21.5704 11.0797 22 10.0149 22C8.9501 22 7.92889 21.5704 7.17594 20.8056C6.423 20.0409 6 19.0037 6 17.9222C6 16.8406 6.423 15.8034 7.17594 15.0387L13.322 8.79625C13.824 8.28642 14.5048 8 15.2147 8C15.9246 8 16.6054 8.28642 17.1073 8.79625C17.6093 9.30608 17.8913 9.99756 17.8913 10.7186C17.8913 11.4396 17.6093 12.1311 17.1073 12.6409L10.9546 18.8833C10.7036 19.1382 10.3632 19.2814 10.0082 19.2814C9.65329 19.2814 9.31289 19.1382 9.06191 18.8833C8.81093 18.6284 8.66993 18.2827 8.66993 17.9222C8.66993 17.5616 8.81093 17.2159 9.06191 16.961L14.7399 11.2008"
                            stroke="#6B69DF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div class="fileitem__block--text">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--help"
                    v-tooltip.top-center="
                      'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                    "
                    >?</span
                  >
                </div>
              </div>
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="file_document_5.class"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Прикрепить пасажирский купон / посадочный талон
                    </div>

                    <div
                      class="fileitem__block--file"
                      v-if="file_document_5.name"
                    >
                      <div class="fileitem__block--icon">
                        <svg
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.1242 13.5002L12.3742 15.7502L16.8742 11.2502M8.8133 5.28438C9.62052 5.21996 10.3869 4.90253 11.0032 4.37729C12.4415 3.15158 14.5569 3.15158 15.9952 4.37729C16.6116 4.90253 17.3779 5.21996 18.1852 5.28438C20.0689 5.4347 21.5648 6.93055 21.7151 8.81428C21.7794 9.6215 22.0969 10.3878 22.6222 11.0042C23.8478 12.4425 23.8478 14.5579 22.6222 15.9962C22.0969 16.6125 21.7794 17.3789 21.7151 18.1862C21.5648 20.0699 20.0689 21.5657 18.1852 21.7161C17.378 21.7804 16.6116 22.0979 15.9952 22.6232C14.5569 23.8488 12.4415 23.8488 11.0032 22.6232C10.3869 22.0979 9.62052 21.7804 8.8133 21.7161C6.92957 21.5657 5.43372 20.0699 5.2834 18.1862C5.21898 17.3789 4.90155 16.6125 4.37631 15.9962C3.1506 14.5579 3.1506 12.4425 4.37631 11.0042C4.90155 10.3878 5.21898 9.6215 5.2834 8.81428C5.43372 6.93055 6.92957 5.4347 8.8133 5.28438Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div class="fileitem__block--text">
                        {{ file_document_5.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 14.5632L12.8539 20.8056C12.101 21.5704 11.0797 22 10.0149 22C8.9501 22 7.92889 21.5704 7.17594 20.8056C6.423 20.0409 6 19.0037 6 17.9222C6 16.8406 6.423 15.8034 7.17594 15.0387L13.322 8.79625C13.824 8.28642 14.5048 8 15.2147 8C15.9246 8 16.6054 8.28642 17.1073 8.79625C17.6093 9.30608 17.8913 9.99756 17.8913 10.7186C17.8913 11.4396 17.6093 12.1311 17.1073 12.6409L10.9546 18.8833C10.7036 19.1382 10.3632 19.2814 10.0082 19.2814C9.65329 19.2814 9.31289 19.1382 9.06191 18.8833C8.81093 18.6284 8.66993 18.2827 8.66993 17.9222C8.66993 17.5616 8.81093 17.2159 9.06191 16.961L14.7399 11.2008"
                            stroke="#6B69DF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div class="fileitem__block--text">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--help"
                    v-tooltip.top-center="
                      'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                    "
                    >?</span
                  >
                </div>
              </div>
            </div>

            <div class="form__block--selectline">
              <div class="row">
                <div class="col-xl-5">
                  <div class="form__block--line">
                    <div class="form__block--input">
                      <div class="form__block--title">
                        Сведения об отсутствии/наличии задолженностей
                      </div>
                      <input
                        type="text"
                        class="input-linck input-linck--white"
                        placeholder="Сведения о задолженностей"
                        value="Документ 21.05.2021.word"
                        disabled=""
                      />
                      <!-- input--error -->
                      <span class="input-required">{{
                        $t('registration.required.error')
                      }}</span>
                      <!--
												<div class="subsidizing__banner--button section--link">
													<a class="link--active">Добавить +</a>
												</div>
												-->
                    </div>
                  </div>
                </div>
                <div class="col-xl-5">
                  <div class="form__block--line">
                    <div class="form__block--input">
                      <div class="form__block--title">Расчетный счет</div>
                      <input
                        type="text"
                        class="input-linck input-linck--white"
                        placeholder="Расчетный счет"
                        value="*2131 *3123 *4345"
                        disabled=""
                      />
                      <!-- input--error -->
                      <span class="input-required">{{
                        $t('registration.required.error')
                      }}</span>
                      <!--
												<div class="subsidizing__banner--button section--link">
													<a class="link--active">Добавить +</a>
												</div>
												-->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form__block--history">
              <div class="form__block--title">История всех действий</div>
              <div class="row">
                <!--

									<div class="col-lg-12">
										<div class="history__item history__item--new">
											<div class="history__item--date">
												<span>27.01.2021</span> <span class="history__item--title">Возвращено с комментариями</span>
											</div>
											<div class="history__item--text">
												Документ не правильно оформлен, факт пересечения государственной границы РК туриста
											</div>
										</div>
									</div>

									-->

                <div
                  class="col-lg-12"
                  v-for="route in application.data.routes"
                  v-if="route.message"
                >
                  <div class="history__item">
                    <div class="history__item--date">
                      <span
                        >{{ route.created_at.substr(8, 2) }}.{{
                          route.created_at.substr(5, 2)
                        }}.{{ route.created_at.substr(0, 4) }}</span
                      >
                      <span class="history__item--title">Комментарий</span>
                    </div>
                    <div class="history__item--text">
                      {{ route.message }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="history__item">
                    <div class="history__item--date">
                      <span
                        >{{ application.data.created_at.substr(8, 2) }}.{{
                          application.data.created_at.substr(5, 2)
                        }}.{{ application.data.created_at.substr(0, 4) }}</span
                      >
                    </div>
                    <div class="history__item--text">
                      Отправка заявки №{{ application.data.id }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__wrapper--footer">
              <div class="section--link">
                <button
                  class="next__stage input__button"
                  @click="resend"
                  v-if="application.data.status.slug == 'resend'"
                >
                  Изменить согласно комментариям
                </button>
                <button class="next__stage input__button" disabled="" v-else>
                  Изменить согласно комментариям
                </button>
                <button class="next__stage input__button" disabled="">
                  Отозвать заявление
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '../../../../boot/axios'

export default {
  name: 'ViewsTourist',
  data() {
    return {
      user: null,
      application: null,
      fileDocuments: {
        name: null, //	'Название документа.doc',
        class: null, //	'add_tourist--success'
      },
      file_document_1: {
        name: null,
        class: null,
      },
      file_document_2: {
        name: null,
        class: null,
      },
      file_document_3: {
        name: null,
        class: null,
      },
      file_document_4: {
        name: null,
        class: null,
      },
      file_document_5: {
        name: null,
        class: null,
      },
      userDataSave: [
        {
          name: 'Рамазан',
          surname: 'Есенов',
          patronymic: '',
          region: 'Казахстан',
          passport: '6345453223',
          period: '18 ноября 2021',
        },
      ],

      loading: false,
      options: {
        itemsPerPage: 1,
      },
      headers: [
        { text: 'ФИО туриста', value: 'name' },
        { text: 'Номер паспорта', value: 'passport' },
        { text: 'Гражданство', value: 'region' },
        { text: 'Период пребывания ', value: 'period' },
      ],

      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      imgPhoto: null,
      loaderCapture: false,
      index_for_web: 0,
    }
  },
  methods: {
    resend() {
      const formData = new FormData()

      formData.append('_method', 'put')

      api
        .post(
          'applications/' + this.$route.params.application_id + '/resend',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then((response) => {
          if (response.data.code == 200) {
            alert('Все ok ғой')
          }
        })
    },
  },
  beforeCreate() {
    if (!localStorage.token) {
      this.$router.push('/ru/login')
    }

    api.get('user').then((response) => {
      if (response.data) {
        this.user = response.data
      }
    })

    api
      .get('applications/' + this.$route.params.application_id)
      .then((response) => {
        if (response.data) {
          this.application = response.data

          if (response.data.data.fields.file_document_1.status) {
            this.file_document_1.status =
              response.data.data.fields.file_document_1.status
            this.file_document_1.name =
              response.data.data.fields.file_document_1.name

            if (response.data.data.fields.file_document_1.status == 'success') {
              this.file_document_1.class = 'add_tourist--success'
            }

            if (response.data.data.fields.file_document_1.status == 'fail') {
              this.file_document_1.class = 'add_tourist--error'
            }
          }

          if (response.data.data.fields.file_document_2.status) {
            this.file_document_2.status =
              response.data.data.fields.file_document_2.status
            this.file_document_2.name =
              response.data.data.fields.file_document_2.name

            if (response.data.data.fields.file_document_2.status == 'success') {
              this.file_document_2.class = 'add_tourist--success'
            }

            if (response.data.data.fields.file_document_2.status == 'fail') {
              this.file_document_2.class = 'add_tourist--error'
            }
          }

          if (response.data.data.fields.file_document_3.status) {
            this.file_document_3.status =
              response.data.data.fields.file_document_3.status
            this.file_document_3.name =
              response.data.data.fields.file_document_3.name

            if (response.data.data.fields.file_document_3.status == 'success') {
              this.file_document_3.class = 'add_tourist--success'
            }

            if (response.data.data.fields.file_document_3.status == 'fail') {
              this.file_document_3.class = 'add_tourist--error'
            }
          }

          if (response.data.data.fields.file_document_4.status) {
            this.file_document_4.status =
              response.data.data.fields.file_document_4.status
            this.file_document_4.name =
              response.data.data.fields.file_document_4.name

            if (response.data.data.fields.file_document_4.status == 'success') {
              this.file_document_4.class = 'add_tourist--success'
            }

            if (response.data.data.fields.file_document_4.status == 'fail') {
              this.file_document_4.class = 'add_tourist--error'
            }
          }

          if (response.data.data.fields.file_document_5.status) {
            this.file_document_5.status =
              response.data.data.fields.file_document_5.status
            this.file_document_5.name =
              response.data.data.fields.file_document_5.name

            if (response.data.data.fields.file_document_5.status == 'success') {
              this.file_document_5.class = 'add_tourist--success'
            }

            if (response.data.data.fields.file_document_5.status == 'fail') {
              this.file_document_5.class = 'add_tourist--error'
            }
          }
        }
      })
  },
}
</script>

<style scoped></style>
